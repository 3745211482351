import 'assets/styles/Steps_of_Booking.css';
import effective from "assets/images/services_images/effective.png";
import friendly from "assets/images/services_images/friendly.png";
import full_service from "assets/images/services_images/full-service.png";
import pest from "assets/images/services_images/pest.png";
import three from "assets/images/services_images/three.png";
import security from "assets/images/services_images/security.png";


function Services() {
    return (
        <section className="offerings">
            <h2>
                Our Offerings
            </h2>
            <div className="belowtext"></div>
            <div className="offerings-steps">
                <div class="container">
                    <div class="inner-container">
                        <img src={pest} alt="Pest Control" />
                        <main>
                            <h3>Pest Control</h3>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </p>
                        </main>
                    </div>
                    <div class="inner-container">
                        <img src={full_service} alt="Full Service Storage" />
                        <main>
                            <h3>Full Service Storage</h3>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </p>
                        </main>
                    </div>
                    <div class="inner-container">
                        <img src={friendly} alt="User-Friendly Portal" />
                        <main>
                            <h3>User-Friendly Portal</h3>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </p>
                        </main>
                    </div>
                </div>
                <div class="container">
                    <div class="inner-container">
                        <img src={three} alt="Three-Level Packaging" />
                        <main>
                            <h3>Three-Level Packaging</h3>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </p>
                        </main>
                    </div>
                    <div class="inner-container">
                        <img src={effective} alt="Cost Effective Plans" />
                        <main>
                            <h3>Cost Effective Plans</h3>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </p>
                        </main>
                    </div>
                    <div class="inner-container">
                        <img src={security} alt="High Level Security" />
                        <main>
                            <h3>High Level Security</h3>
                            <p>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                            </p>
                        </main>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Services;
