// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCkg8iD9RUxlVzknf43L0PFnp7thRGP658",
    authDomain: "apni--storage.firebaseapp.com",
    projectId: "apni--storage",
    storageBucket: "apni--storage.appspot.com",
    messagingSenderId: "662906344996",
    appId: "1:662906344996:web:9dd9a7085106e8a60b2ab1",
    measurementId: "G-JB2KT99W6H"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);