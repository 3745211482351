import logo from 'logo.png';
import 'assets/styles/Home.css';
import Login from "pages/Login";
import App from "pages/Home";
import { slide as Menu } from 'react-burger-menu';
import "assets/styles/Sidebar.css";
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import { AiOutlineShoppingCart } from 'react-icons/ai'

function Layout() {
    return (
        <Router>
            <header className="top-header w-full bg-primary flex flex-col align-center justify-center fixed top-0 z-10 overflow-hidden h-25" id="outer-container">
                <div className='details-header w-full bg-amber-600 flex align-center justify-end mr-10 z-10 overflow-hidden'>
                    <ul>
                        <li className="px-2 text-black">
                            <Link className="text-s">+91-6353062359</Link>
                        </li>
                        <li className="px-2 text-black">
                            <Link className="text-s">Info@apnistorage.com</Link>
                        </li>
                        {/* <li className="px-2 text-black">
                            <Link to="#" onClick={(e) => {
                                window.location.href = "Info@apnistorage.com";
                                e.preventDefault();
                            }} className="text-s">Info@apnistorage.com</Link>
                        </li> */}
                    </ul>
                </div>
                {/* <Menu outerContainerId={'outer-container'} >
                    <ul id='navigation'>
                        <li>
                            Storage Options
                        </li>
                        <li>
                            P2P Storage
                        </li>
                        <li className="login-btn">
                            <Link to="/login">Login</Link>
                        </li>
                    </ul>
                </Menu> */}
                <div className="App-header w-full bg-primary flex align-center justify-center z-10 text-xl overflow-hidden h-16">
                    <div className="logo-div flex text-2xl text-black items-center flex-row">
                        <Link to="/"><img className="App-logo object-contain px-10px" src={logo} alt="logo" /></Link>
                        <p>apnistorage.com</p>
                    </div>
                    <nav className="App-Nav flex items-center">
                        <ul id="navigation" className='items-center flex flex-row list-none px-20px'>
                            <li className="cart">
                                <AiOutlineShoppingCart />
                            </li>
                            <li className="login-btn py-1 px-2 bg-white text-primary rounded">
                                <Link to="/login" className="text-primary decoration-none">Login</Link>
                            </li>
                        </ul>
                    </nav>
                </div>
            </header>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/login" element={<Login />} />
            </Routes>
        </Router >

    );
};

export default Layout;
