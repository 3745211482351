import './Steps_of_Booking';

function Testimonial() {
    return (
        <section className="testimonial">
            <h2>
                Testimonials
            </h2>
            <div className="belowtext"></div>
            <div className="testimonial-steps">
                <div><h3>Yatharth</h3><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p></div>
                <div><h3>Pushpak</h3><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p></div>
            </div>
        </section>
    );
}

export default Testimonial;
