import './Steps_of_Booking';

function Peer() {
    return (
        <section className="peers">
            <h2>
                Peer to Peer Storage
            </h2>
            <div className="belowtext"></div>
            <div className="peers-steps">
                <div><h3>Rent Your Space</h3><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p></div>
                <div><h3>Get Space on Rent</h3><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p></div>
            </div>
        </section>
    );
}

export default Peer;
