import 'assets/styles/Home.css';
import Steps_of_Booking from 'components/Home/Steps_of_Booking.js';
import Cities from "components/Home/Cities.js";
import Storage from "components/Home/Storage_Options.js";
import Services from "components/Home/Services.js";
import Peer from "components/Home/Peer.js";
import Testimonial from "components/Home/Testimonial.js";
import Footer from "components/Home/Footer.js";

function Home() {

  return (
    <section>
      <section className="start">
        <div className="start-data">
          <h1 className="text-20xl font-bold">Hungry for space? We deliver.</h1>
          <div className="start-data-book">
            <h2 className="text-20xl font-bold">Starting @ INR 666</h2>
            <button className="book-now">Book Now</button>
          </div>
        </div>
      </section>
      <Steps_of_Booking />
      <Cities />
      <Storage />
      <Services />
      <Peer />
      <Testimonial />
      <Footer />
    </section>
  );
}

export default Home;
