import 'assets/styles/footer_css.css';
import logo from "logo.png";

function Footer() {
    return (
        <section className="footer">
            <div className="footer-steps">
                <div className="f-container">
                    <div className="f-f-container">
                        <div>
                            <img src={logo} alt="logo" />
                            <h1>apnistorage.com</h1>
                        </div>
                        <p>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                        </p>
                    </div>
                    <div className='extra'>
                        <h2>
                            Extra Links
                        </h2>
                        <ul>
                            <li>
                                About Us
                            </li>
                            <li>
                                Terms
                            </li>
                            <li>
                                Contact Us
                            </li>
                            <li>
                                Privacy
                            </li>
                            <li>
                                FAQ
                            </li>
                            <li>
                                Prohibited
                            </li>
                        </ul>
                    </div>
                </div>
                <hr></hr>
                <div className="copyright-container">
                    <p>apnistorage © 2023. All Rights Reserved.</p>
                </div>
            </div>
        </section>
    );
}

export default Footer;
